<template>
	<div class="check-partial">
		<div
			class="check-partial__icon-container"
			:class="{ 'check-partial__icon-container--last': props.last, 'check-partial__icon-container--inactive': !props.done }">
			<v-icon v-if="props.done" class="check-partial__icon" icon="mdi-check" />
		</div>
		<p class="check-partial__text" :class="{ 'check-partial__text--inactive': !props.done }">{{ props.text }}</p>
	</div>
</template>

<script setup lang="ts">
const props = defineProps({
	done: {
		type: Boolean,
		required: true,
	},
	text: {
		type: String,
		required: true,
	},
	last: {
		type: Boolean,
		required: false,
	},
	first: {
		type: Boolean,
		required: false,
	},
})
</script>

<style lang="scss">
@import './CheckPartial.scss';
</style>
